"use client";

import "../styles/gradiant-bg.css";
import Image from "next/image";

function GradientBG() {
  return (
    <div className="relative h-screen w-full md:w-screen overflow-hidden bg-black">
      <Image
        src="/assets/images/vector-1.svg"
        width={0}
        height={0}
        alt="_"
        className="absolute object-fill selection:bg-none"
        id="vector_1"
        loading="lazy"
      />
      <Image
        src="/assets/images/vector-2.svg"
        width={0}
        height={0}
        alt="_"
        className="absolute object-cover selection:bg-none"
        id="vector_2"
        loading="lazy"
      />
      <Image
        src="/assets/images/vector-3.svg"
        width={0}
        height={0}
        alt="_"
        className="absolute object-cover selection:bg-none"
        id="vector_3"
        loading="lazy"
      />
      <Image
        src="/assets/images/vector-4.svg"
        width={0}
        height={0}
        alt="_"
        className="absolute object-cover selection:bg-none"
        id="vector_4"
        loading="lazy"
      />
      <Image
        src="/assets/images/vector-5.svg"
        width={0}
        height={0}
        alt="_"
        className="absolute object-cover selection:bg-none"
        id="vector_5"
        loading="lazy"
      />
      <Image
        src="/assets/images/vector-6.svg"
        width={0}
        height={0}
        alt="_"
        className="absolute object-cover selection:bg-none"
        id="vector_6"
        loading="lazy"
      />
    </div>
  );
}

export default GradientBG;