"use client";

import { useTranslations } from "next-intl";
import Image from "next/image";
import { gsap } from "gsap/dist/gsap";
import { useEffect, useRef, useState } from "react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
// import useSWR from "swr";
// import { fetchGoogleRating } from "actions/crawler";

// const URL =
//   "https://maps.googleapis.com/maps/api/place/details/json?&place_id=ChIJ5Xs_YT4ZQjER3P3nOqy3jZU&key=AIzaSyAMb1VK65dMhMk2LgIsTqkFiAgUAif5fjo&fields=rating";

function YourTravelPartnerSection() {
  const t = useTranslations("Index");
  const [stats, setStats] = useState({ value: 0 });
  const [stats2, setStats2] = useState({ value: 0 });
  const triggerRef = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  // const ggRating = useSWR(URL, () => fetchGoogleRating());

  useEffect(() => {
    let ctx = gsap.context(() => {
      const target = {
        value: stats.value,
      };

      const target2 = {
        value: stats2.value,
      };

      gsap.to(target, {
        scrollTrigger: {
          trigger: triggerRef.current,
          start: "top bottom",
          end: "+=200%",
          pin: false,
        },
        duration: 3.2,
        value: "+=600",
        roundProps: "value",
        ease: "power1.out",
        onUpdate() {
          setStats({ value: target.value });
        },
      });

      gsap.to(target2, {
        scrollTrigger: {
          trigger: triggerRef.current,
          start: "top bottom",
          end: "+=200%",
          pin: false,
        },
        duration: 4,
        value: "+=70",
        roundProps: "value",
        ease: "power1.out",
        onUpdate() {
          setStats2({ value: target2.value });
        },
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <section
      className=" font-degular-display-medium flex w-full max-w-screen-xl 
          px-[40px] md:px-0 flex-col-reverse md:space-x-[152px] pt-[0px] md:pt-[78px] pb-[88px] 
          md:flex-row md:items-start "
    >
      <div
        className=" flex flex-1 flex-row items-center justify-between 
                    md:space-x-[48px] mt-[64px] md:mt-0
                    w-full space-x-[10px]"
        ref={triggerRef}
      >
        <div className=" flex-1 flex flex-col items-center space-y-[12px] md:space-y-[16px] text-center ">
          <p className=" text-[50px] md:text-[72px] leading-[54px] md:leading-[64.8px]">
            <span id="counter">{stats.value}</span>
          </p>
          <p className=" text-[20px] md:text-[32px] leading-none md:leading-9">
            {t("bookings_per_day")}
          </p>
        </div>
        <div className="flex-1 flex flex-col items-center space-y-[12px] md:space-y-[16px] text-center">
          <p className=" text-[50px] md:text-[72px] leading-[54px] md:leading-[64.8px]">
            <span id="counter">{stats2.value}</span>%
          </p>
          <p className=" text-[20px] md:text-[32px] leading-none md:leading-9">
            {t("customer_retention")}
          </p>
        </div>
        <div className="flex-1 flex flex-col items-center space-y-[12px] md:space-y-[16px] text-center">
          <p className=" text-[50px] md:text-[72px] leading-[54px] md:leading-[64.8px]">
            <span id="counter">
              {/* {ggRating?.data?.status == "OK"
                ? ggRating?.data?.result?.rating
                : "4.7"} */}
              4.7/5
            </span>
          </p>
          <p className=" text-[20px] md:text-[32px] leading-none md:leading-9">
            {t("customer_satisfaction")}
          </p>
        </div>
      </div>
      <div className="flex flex-1 flex-col items-start space-y-[28px]  ">
        <p
          className=" font-degular-display-semibold text-[40px] md:text-[64px] 
            leading-[25px] md:leading-[64px] bg-[url('/assets/images/property1-variant3.png')] 
            bg-no-repeat bg-cover text-transparent bg-clip-text "
        >
          {t("your_travel_partner")}
        </p>
        <span className=" h5">
          <p>{t("your_travel_partner_presentation")}</p>
          <p>{t("your_travel_partner_presentation2")}</p>
        </span>
        <div className=" flex w-full flex-col md:flex-row items-center justify-between">
          <p className=" text-[24px] opacity-50 mb-[10px] md:mb-0 ">
            {t("we_are_trusted_by")}
          </p>
          <div className="flex -space-x-[20px] rtl:space-x-reverse">
            <Image
              src="/assets/images/airasia_logo.png"
              alt="air-asia"
              height={60}
              width={60}
              className="rounded-full"
              quality={100}
            />
            <Image
              src="/assets/images/jayride_logo.png"
              alt="jay-ride"
              height={60}
              width={60}
              className="rounded-full"
              quality={100}
            />
            <Image
              src="/assets/images/bookaway_logo.jpeg"
              alt="book-away"
              height={60}
              width={60}
              className="rounded-full"
              quality={100}
            />
            <Image
              src="/assets/images/evaair_logo.png"
              alt="eva-air"
              height={60}
              width={60}
              className="rounded-full"
              quality={100}
            />
            <Image
              src="/assets/images/Booking.com_Icon_2022.png"
              alt="booking.com"
              height={60}
              width={60}
              className="rounded-full"
              quality={100}
            />
            <Image
              src="/assets/images/5198c9ecd826b7b65e69ce201bc21550.jpg"
              alt="traveloka"
              height={60}
              width={60}
              quality={100}
              className="rounded-full"
            />
            <Image
              src="/assets/images/skyyoy_logo.png"
              alt="skyjoy"
              height={60}
              width={60}
              className="rounded-full"
              quality={100}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default YourTravelPartnerSection;
