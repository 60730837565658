"use client";

import "../styles/booking.css";

function BookingForm() {
  return (
    <div className="flex w-full flex-col items-start md:max-w-[400px]">
      <div id="topBookingForm" />
    </div>
  );
}

export default BookingForm;
